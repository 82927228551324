import React from 'react'
import styled from '@emotion/styled'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const Logo = props => (
  <Root {...props}>
    <SVG width="80" height="49" viewBox="0 0 80 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.39906 45.2161L6.9128 38.1462H8.01257L10.4006 45.2161L12.8515 38.2405H14.9568L11.0919 48.6725H9.70935L7.44698 41.9797L5.02749 48.6725H3.64493L0 38.2405H2.10526L4.39906 45.2161ZM22.7494 48.4526H20.7384V38.209H22.718V48.4526H22.7494ZM36.8578 46.7244V48.4526H28.8452L33.8413 39.9372H29.5994V38.209H37.1092L32.1131 46.7244H36.8578ZM48.6724 44.6819H45.7502L47.227 41.0056L48.6724 44.6819ZM49.3009 46.3159L50.1807 48.484H52.3174L48.0126 38.0205H46.5043L42.0738 48.4526H44.1791L45.0903 46.2845H49.3009V46.3159ZM60.1414 42.9537V39.843H60.7384C61.9953 39.843 62.6551 40.3771 62.6551 41.3512C62.6551 42.3881 61.9953 42.9223 60.707 42.9223H60.1414V42.9537ZM62.4038 44.1478C63.912 43.8335 64.729 42.8281 64.729 41.257C64.729 39.3402 63.4093 38.2405 61.1155 38.2405H58.1304V48.484H60.11V44.3677H60.3614L63.1265 48.484H65.5774L62.4038 44.1478ZM73.5585 46.7244V39.9687H74.0927C75.381 39.9687 76.1665 40.1886 76.8264 40.7856C77.5177 41.4141 77.9262 42.3567 77.9262 43.3308C77.9262 44.3049 77.5177 45.3104 76.8264 45.9074C76.198 46.473 75.381 46.7244 74.0927 46.7244H73.5585ZM71.5789 48.4526H74.5012C76.0408 48.4526 77.172 48.1069 78.1461 47.2585C79.3087 46.3159 80 44.839 80 43.3308C80 40.3143 77.7376 38.209 74.5012 38.209H71.5789V48.4526Z" fill="black"/>
      <path d="M50.2435 17.7848L60.5184 0H78.4289L59.1987 33.2757L50.2435 17.7848ZM19.4815 0H1.57104L20.8012 33.2757L29.7564 17.7533L19.4815 0ZM40 35.5381L50.2749 17.7533L40 0L29.725 17.7848L40 35.5381Z" fill="#0068B7"/>
    </SVG>
  </Root>
)

const Root = styled.div`
  ${aspectRatio(80, 49)};
`

const SVG = styled.svg`
  ${aspectRatioChild};
`

export default Logo
