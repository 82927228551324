import React from 'react'
import { css } from '@emotion/core'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'
import { fonts } from '@/utils/preset'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Logo from './Logo'

const LogoButton = props => {
  const { theme, classes, children, className, color, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      css={styles.Root}
      {...other}
    >
      <Logo css={styles.Logo}/>
    </MuiButton>
  )
}

const getStyles = (props) => {
  if (props.type === 'contrast') {
    return {
      backgroundColor: props.theme.background.contrast,
      color: props.theme.text.contrast,
      hoverBackgroundColor: darken(props.theme.background.contrast, 0.1)
    }
  } else if (props.scale) {
    return {
      backgroundColor: props.theme.scale[props.scale],
      color: props.theme.scale.contrast[props.scale],
      hoverBackgroundColor: props.theme.scale.hover[props.scale]
    }
  } else {
    return {
      backgroundColor: props.theme.background.default,
      color: props.theme.text.default,
      hoverBackgroundColor: lighten(props.theme.background.default, 0.1)
    }
  }
}

const muiStyles = props => {
  const styles = getStyles(props)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      borderRadius: 0,
      padding: 0,
      fontSize: 0,
      fontFamily: fonts.base,
      boxShadow: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        },
        '&:disabled': {
          backgroundColor: styles.backgroundColor
        }
      },
      '&:disabled': {
        backgroundColor: styles.backgroundColor,
        color: styles.color,
        opacity: 0.6
      }
    }
  }
}

const styles = {
  Root: css`
    height: 128px;
    width: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${mq.and(desktop, laptop, tabletL)} {
      padding: 32px;
    }
    @media ${mq.and(tabletS, mobile)} {
      padding: 12px;
    }
  `,
  Logo: css`
    width: 100%;
  `
}

export default ThemeConsumer(WithStylesProps(muiStyles)(LogoButton))
