import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { fonts } from '@/utils/preset'
import { getThemeColor } from '@/utils/themes'

const Typography = props => {
  const { lang = 'base', variant, component, theme, className, color, type, children, ...other } = props
  let Root = styles[lang][variant]
  Root = component ? Root.withComponent(component) : Root
  return (
    <Root className={className} theme={theme} color={color} type={type} {...other}>{ children }</Root>
  )
}
export default ThemeConsumer(Typography)

const Base = styled.div`
  color: ${props => {
    if (props.type === 'contrast') {
      return props.theme.text.contrast
    } else if (props.type === 'default') {
      return props.theme.text.default
    } else if (props.color) {
      return getThemeColor(props.color, props.theme)
    } else if (props.scale) {
      return props.theme.scale[props.scale]
    } else if (props.error) {
      return props.theme.error
    } else {
      return 'currentColor'
    }
  }};
  overflow-wrap: break-word;
`

const styles = {
  base: {
    h1: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 40px;
      font-weight: bold;
      line-height: 1.3;
      @media ${mq.and(mobile)} {
        font-size: 26px;
      }
    `.withComponent('h1'),
    h2: styled(Base)`
      font-size: 32px;
      font-weight: bold;
      line-height: 1.3;
      @media ${mq.and(mobile)} {
        font-size: 24px;
      }
    `.withComponent('h2'),
    h3: styled(Base)`
      font-size: 26px;
      font-weight: bold;
      line-height: 1.3;
      @media ${mq.and(mobile)} {
        font-size: 20px;
      }
    `.withComponent('h3'),
    h4: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      @media ${mq.and(mobile)} {
        font-size: 16px;
      }
    `.withComponent('h4'),
    h5: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      @media ${mq.and(mobile)} {
        font-size: 14px;
      }
    `.withComponent('h5'),
    body1: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 18px;
      line-height: 1.75;
      @media ${mq.and(mobile)} {
        font-size: 14px;
      }
    `.withComponent('p'),
    body2: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 16px;
      line-height: 1.75;
      @media ${mq.and(mobile)} {
        font-size: 14px;
      }
    `.withComponent('p'),
    body3: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 14px;
      line-height: 1.5;
      @media ${mq.and(mobile)} {
        font-size: 12px;
      }
    `.withComponent('p'),
    caption1: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 14px;
      line-height: 1.5;
    `.withComponent('p'),
    caption2: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 12px;
      line-height: 1.5;
      font-weight: bold;
    `.withComponent('p'),
    caption3: styled(Base)`
      font-family: ${fonts.jp};
      font-size: 12px;
      line-height: 1.5;
    `.withComponent('p')
  },
  en: {
    h1: styled(Base)`
      font-family: ${fonts.en};
      font-size: 88px;
      line-height: 1;
      @media ${mq.and(mobileS)} {
        font-size: 66px;
      }
      visibility: hidden;
      html.wf-lato-n4-active &,
      html.wf-lato-n4-inactive & {
        visibility: visible;
      }
    `.withComponent('h1'),
    h3: styled(Base)`
      font-family: ${fonts.en};
      font-size: 32px;
      line-height: 1.312532;
      font-weight: bold;
      @media ${mq.and(mobile)} {
        font-size: 28px;
      }
      visibility: hidden;
      html.wf-lato-n7-active &,
      html.wf-lato-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('h3'),
    h4: styled(Base)`
      font-family: ${fonts.en};
      font-size: 22px;
      line-height: 1.5;
      font-weight: bold;
      @media ${mq.and(mobile)} {
        font-size: 18px;
      }
      visibility: hidden;
      html.wf-lato-n7-active &,
      html.wf-lato-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('h4'),
    h5: styled(Base)`
      font-family: ${fonts.en};
      font-size: 18px;
      line-height: 1.5;
      font-weight: bold;
      @media ${mq.and(mobile)} {
        font-size: 14px;
      }
      visibility: hidden;
      html.wf-lato-n7-active &,
      html.wf-lato-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('h5'),
    caption: styled(Base)`
      font-family: ${fonts.en};
      font-size: 12px;
      line-height: 1.5;
      font-weight: bold;
      @media ${mq.and(mobile)} {
        font-size: 10px;
      }
      visibility: hidden;
      html.wf-lato-n7-active &,
      html.wf-lato-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('p'),
    subtitle: styled(Base)`
      font-family: ${fonts.en};
      font-size: 15px;
      line-height: 1.5;
      font-weight: bold;
      @media ${mq.and(mobile)} {
        font-size: 14px;
      }
      visibility: hidden;
      html.wf-lato-n7-active &,
      html.wf-lato-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('p')
  }
}
