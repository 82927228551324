// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-recruit-career-index-js": () => import("./../../../src/pages/recruit/career/index.js" /* webpackChunkName: "component---src-pages-recruit-career-index-js" */),
  "component---src-pages-recruit-company-index-js": () => import("./../../../src/pages/recruit/company/index.js" /* webpackChunkName: "component---src-pages-recruit-company-index-js" */),
  "component---src-pages-recruit-fresh-index-js": () => import("./../../../src/pages/recruit/fresh/index.js" /* webpackChunkName: "component---src-pages-recruit-fresh-index-js" */),
  "component---src-pages-recruit-index-js": () => import("./../../../src/pages/recruit/index.js" /* webpackChunkName: "component---src-pages-recruit-index-js" */),
  "component---src-pages-recruit-interview-index-js": () => import("./../../../src/pages/recruit/interview/index.js" /* webpackChunkName: "component---src-pages-recruit-interview-index-js" */),
  "component---src-pages-recruit-statistics-index-js": () => import("./../../../src/pages/recruit/statistics/index.js" /* webpackChunkName: "component---src-pages-recruit-statistics-index-js" */),
  "component---src-pages-services-dev-index-js": () => import("./../../../src/pages/services/dev/index.js" /* webpackChunkName: "component---src-pages-services-dev-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

