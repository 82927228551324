import { darken, lighten } from '@material-ui/core/styles/colorManipulator'

export default {
  base: {
    primary: {
      light: '#6087FF',
      base: '#165BCA',
      dark: '#0033A1',
      contrast: {
        light: '#fff',
        base: '#fff',
        dark: '#fff'
      }
    },
    secondary: {
      light: '#6EFFE8',
      base: '#1DE9B6',
      dark: '#00B686',
      contrast: {
        light: '#fff',
        base: '#fff',
        dark: '#fff'
      }
    },
    scale: {
      50: '#EFEFF2',
      100: '#D1D1DB',
      200: '#B4B3C3',
      300: '#9695AC',
      400: '#797894',
      500: '#5B5A7D',
      600: '#47466A',
      700: '#353456',
      800: '#242340',
      900: '#151428',
      contrast: {
        50: '#151428',
        100: '#151428',
        200: '#151428',
        300: '#151428',
        400: '#EFEFF2',
        500: '#EFEFF2',
        600: '#EFEFF2',
        700: '#EFEFF2',
        800: '#EFEFF2',
        900: '#EFEFF2'
      },
      hover: {
        50: darken('#EFEFF2', 0.1),
        100: darken('#D1D1DB', 0.1),
        200: lighten('#B4B3C3', 0.1),
        300: lighten('#9695AC', 0.1),
        400: lighten('#797894', 0.1),
        500: lighten('#5B5A7D', 0.1),
        600: lighten('#47466A', 0.1),
        700: lighten('#353456', 0.1),
        800: lighten('#242340', 0.1),
        900: lighten('#151428', 0.1)
      }
    },
    text: {
      default: '#fff',
      contrast: '#151428'
    },
    background: {
      default: '#151428',
      contrast: '#fff'
    },
    error: '#F54F27'
  }
}

export const parseThemeColor = keyPath => {
  const array = keyPath.split('.')
  const type = array[0]
  const tone = array.length > 1 ? array[1] : 'base'
  return { type, tone }
}

export const getThemeColor = (keyPath, theme) => {
  const { type, tone } = parseThemeColor(keyPath)
  return theme[type][tone]
}

export const getThemeContrastColor = (keyPath, theme) => {
  const { type, tone } = parseThemeColor(keyPath)
  return theme[type].contrast[tone]
}
